// http://ionicframework.com/docs/theming/
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');

@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import "theme/custom-ion-colors.scss";
// Add base app styles
@import "theme/app-defaults.scss";
// Add base shell styles
@import "theme/shell-defaults.scss";

:host {
  --page-margin: var(--app-broad-margin);
  --page-background: var(--app-background-shade);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.logobanner
{
  max-width: 200px;
}

ion-tab-bar {
  bottom: 10px;
  position: relative;
  border-radius: 0px;
  width: 100%;
  margin: 0 auto;
}

ion-tab-button {
  --color: var(--ion-color-medium);
  --color-selected: #910F14;

  &::before {
    background-color: transparent;
    display: block;
    content: "";
    margin: 0 auto;
    width: 20px;
    height: 2px;
  }

  &.tab-selected::before {
    background-color: #910F14;
  }
}


ion-input {
  input {
    text-align: right;
    /* IF YOU ALSO WANT TO PUSH PLACHEHOLDER TO THE RIGHT USE THE FOLLOWING, IF NOT JUST DELETE THE ::-webkit-input-placeholder*/
    ::-webkit-input-placeholder {
      text-align: right;
    }
  }
}

.input-item {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --highlight-height: 0;
  border: none;
  outline: none;
}

.logoutButton{
  --color: #910F14;
  font-weight: bold;
  --ion-color-primary: #EFEFF0;
  --background-activated: #bbbfbb;
  --background-focused: #bbbfbb;

  &:focus {
    outline: none;
    border: none;
    --ion-color-primary:#910F14 ;
  }
}
 
.spanClass {
  margin-left: 5px;
}

.frameStyle {
  padding-bottom: 15%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.menuButtons {
  color: #910F14;
}

.menu {
  height: 600px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.bava {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.login-content {
  --background: white;
  --padding-start: var(--page-margin);
  --padding-end: var(--page-margin);
  --padding-top: var(--page-margin);
  --padding-bottom: var(--page-margin);

  .inputs-list {
    --ion-item-background: white;

    .input-item {
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --highlight-height: 0;
      border: none;
  outline: none;
    }
  }

}

.inputItem {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --highlight-height: 0;
      border: none;
  outline: none;
}

.inputFRG-list {
  --ion-item-background: white;

  .input-item {
    --padding-start: 0px;
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --highlight-height: 0;
    border: none;
    outline: none;
  }
}

.forgot-btn {
  --color: #910F14;
  margin: 0px;
  font-style: italic;
  font-size: small;
  --background-activated: #cbd8c7;

  &:focus {
    outline: none;
    border: none;
  }
}

.addButton {
  
  --ion-color-primary: #910F14 !important;
  margin: calc(var(--page-margin) / 2) 0px;
  --background-activated: #fff;

  &:focus {
    outline: none;
    border: none;
    --background-activated: #910F14;
    --ion-color-primary:#fff ;
  }
}

.frgButton {
  --ion-color-primary: #910F14 !important;
  margin: calc(var(--page-margin) / 2) 0px;
  --background-activated: #cbd8c7;

  &:focus {
    outline: none;
    border: none;
  }
}


.label-title {
  color: #910F14;
  font-weight: bold;
}

.label-parag {
  color: #910F14;
}

.requiredField {
  margin-left: 5px;
  color: red;
  font-weight: bold;
}


.title {
  color: #910F14;
  font-weight: bold;
  font-size: large;
}

.listTitle {
  color: black;
  font-weight: bold;
}

.labelCard {
  color: black
}

.saveButton {
  --ion-color-primary: #910F14 !important;
  margin: calc(var(--page-margin) / 2) 0px;
  justify-content: center;
  align-content: center;
  align-items: center;
  --background-activated: #cbd8c7;

  &:focus {
    outline: none;
    border: none;
  }
}

.item-divider {
  background-color: #910F14;
  width: 80px;
  margin-bottom: 2px;
  min-height: 1px !important;
}


@media (min-width:1025px) {
  .login-content {
    --background: #e6e6e6;
    --padding-start: var(--page-margin);
    --padding-end: var(--page-margin);
    --padding-top: var(--page-margin);
    --padding-bottom: var(--page-margin);

    .inputs-list {
      --ion-item-background: white;
      align-content: center;
      align-items: center;
      display: table;
      width: 100%;
      justify-content: center;
      align-content: center;
      align-items: center;
      width: 50%;

    }

    .inputFRG-list {
      --ion-item-background: white;
      align-content: center;
      align-items: center;
      display: table;
      width: 100%;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }

  .forgot-btn {
    --color: #910F14;
    margin: 0px;
    font-style: italic;
    font-size: small;
    --background-activated: #cbd8c7;

    &:focus {
      outline: none;
      border: none;
    }
  }

  .addButton {
    --ion-color-primary: #910F14 !important;
    margin: calc(var(--page-margin) / 2) 0px;
    justify-content: center;
    display: flex;
    align-content: center;
    align-items: center;
    width: 50%;
    --background-activated: #cbd8c7

    &:focus {
      outline: none;
      border: none;
      --ion-color-primary:#910F14 ;
    }
  }

  .inputItem {
    margin: 20px;
    --padding-start: 0px;
    --padding-end: 0px;
    --inner-padding-end: 0px;
  }

  .saveButton {
    --ion-color-primary: #910F14 !important;
    justify-content: center;
    align-content: center;
    align-items: center;
    --background-activated: #cbd8c7;
    
    &:focus {
      outline: none;
      border: none;
    }
  }

  .frgButton {
    --ion-color-primary: #910F14 !important;
    margin: calc(var(--page-margin) / 2) 0px;
    justify-content: center;
    align-content: center;
    align-items: center;
    --background-activated: #cbd8c7;

    &:focus {
      outline: none;
      border: none;
    }
  }

  .saveBTN {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .menu {
    height: 600px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .bava {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .logoutButton{
  --color: #910F14;
  --ion-color-primary: #EFEFF0;
  font-weight: bold;
  --background-activated: #bbbfbb;
  --background-focused: #bbbfbb;

  &:focus {
    outline: none;
    border: none;
    --ion-color-primary:#910F14 ;
  }
}
}



// (Note: Don't change the order of the devices as it may break the correct css precedence)

// (see: https://css-tricks.com/snippets/css/media-queries-for-standard-devices/#iphone-queries)
// (see: https://stackoverflow.com/a/47750261/1116959)

/* ----------- iPhone 4 and 4S ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (-webkit-min-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) // uncomment for only portrait:

// and (orientation: portrait)
// uncomment for only landscape:
// and (orientation: landscape)
  {
  .app-sidemenu {
    .user-info-wrapper {
      .user-name {
        font-size: 18px;
      }
    }

    ion-list {
      ion-item {
        font-size: 14px;
      }
    }
  }
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (-webkit-min-device-pixel-ratio: 2) and (device-aspect-ratio: 40 / 71) // uncomment for only portrait:

// and (orientation: portrait)
// uncomment for only landscape:
// and (orientation: landscape)
  {
  .interests-to-follow-slide {
    .custom-checkbox {
      .checkbox-title {
        font-size: 18px;
      }
    }
  }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
@media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (-webkit-min-device-pixel-ratio: 2) // uncomment for only portrait:

// and (orientation: portrait)
// uncomment for only landscape:
// and (orientation: landscape)
  {}

/* ----------- iPhone X ----------- */
@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-min-device-pixel-ratio : 3) // uncomment for only portrait:

// and (orientation: portrait)
// uncomment for only landscape:
// and (orientation: landscape)
  {}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */
@media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (-webkit-min-device-pixel-ratio: 3) // uncomment for only portrait:

// and (orientation: portrait)
// uncomment for only landscape:
  {}
